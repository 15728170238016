import React from "react";

export default function Footer() {
  return (
    <>
      <div
        className="footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#1A242F",
          padding: "36px 0px 36px 40px",
        }}
      >
        <div className="" style={{ flex: "1" }}>
          <img src="./Assets/logo-image.png" />
          <p
            style={{
              lineHeight: "23.8px",
              fontSize: "14px",
              fontWeight: "500",
              color: "#ECECEC",
              fontFamily: "Work Sans",
              paddingRight: "20px",
            }}
          >
            At V2 Royals, our passion lies in crafting unforgettable experiences
            in three core areas: real estate properties, corporate event
            management, and school competitions
          </p>
        </div>
        <div className="" style={{ flex: "1", paddingLeft: "20px" }}>
          <h3 style={{ color: "#FFFFFF" }}>Services</h3>
          <p>
            <a className="footer-link" href="">
              Properties
            </a>
          </p>
          <p>
            <a className="footer-link" href="">
              Corporate Events
            </a>
          </p>
          <p>
            <a className="footer-link" href="">
              School Competitions
            </a>
          </p>
        </div>
        <div className="" style={{ flex: "1" }}>
          <h3 style={{ color: "#FFFFFF" }}>Quick Links</h3>
          <a href="https://www.facebook.com/" target="_blank">
            <img
              style={{ cursor: "pointer", padding: "0px 2px" }}
              src="./Assets/f-icon1.png"
            ></img>
          </a>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              style={{ cursor: "pointer", padding: "0px 12px" }}
              src="./Assets/i-icon1.png"
            ></img>
          </a>
          <a href="https://x.com/" target="_blank">
            <img
              style={{ cursor: "pointer", padding: "0px 12px" }}
              src="./Assets/t-icon.png"
            ></img>
          </a>
          <a href="https://www.linkedin.com/" target="_blank">
            <img
              style={{ cursor: "pointer", padding: "0px 12px" }}
              src="./Assets/l-icon1.png"
            ></img>
          </a>
        </div>
        <div className="" style={{ flex: "1" }}>
          <h3 style={{ color: "#FFFFFF" }}>Contact Us</h3>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "300",
              color: "#ECECEC",
              fontFamily: "Work Sans",
            }}
          >
            121, 1st floor, 3rd cross, 4th main
          </p>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "300",
              color: "#ECECEC",
              fontFamily: "Work Sans",
            }}
          >
            srinivasanagar, Bangalore-560050
          </p>
        </div>
      </div>
      <div style={{ backgroundColor: "#1A242F" }}>
        <p
          style={{
            lineHeight: "23.8px",
            fontSize: "14px",
            fontWeight: "500",
            color: "#ECECEC",
            fontFamily: "Work Sans",
            textAlign: "center",
            margin: "0",
            padding: "0",
            paddingBottom: "20px",
          }}
        >
          Copyright ©TemplatesJungle. 2022. All right reserved
        </p>
      </div>
    </>
  );
}
