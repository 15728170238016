import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
const toggleMenu = () => {
  var menu = document.querySelector(".nav-menu");
  menu.classList.toggle("active");
};
const BookingSlot = ({ show, onClose }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // Optionally ensure endDate is not before startDate
    if (endDate && date && date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSubmit = () => {
    if (!startDate || !endDate) {
      toast.error("Please select a date first!");
      return;
    }

    setTimeout(() => {
      toast.success("Booking successful!");
      setStartDate(null);
      setEndDate(null);
    }, 1000);
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // const handleSubmit = () => {
  //   if (!selectedDate) {
  //     toast.error("Please select a date first!");
  //     return;
  //   }

  //   setTimeout(() => {
  //     toast.success("Booking successful!");
  //     setSelectedDate(null);
  //   }, 1000);
  // };

  return (
    <div
      className="overlay"
      style={{
        display: show ? "flex" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 10,
      }}
    >
      <div
        className="booking-modal"
        style={{
          width: "400px",
          padding: "20px",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          textAlign: "center",
        }}
      >
        <h2 style={{ color: "#2C67F4", paddingBottom: "10px" }}>
          Book Your Appointment
        </h2>
        {/* <DatePicker 
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
          isClearable
          placeholderText="Select a date"
          className="custom-datepicker"
        /> */}
        <div className="date-model-responsive">
          <label style={{ paddingRight: "10px" }}>Start Date and Time</label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy h:mm aa"
            minDate={new Date()}
            isClearable
            placeholderText="Select start date and time"
            className="custom-datepicker"
          />
        </div>
        <div className="date-model-responsive">
          <label style={{ paddingRight: "10px" }}>End Date and Time</label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy h:mm aa"
            minDate={startDate || new Date()}
            isClearable
            placeholderText="Select end date and time"
            className="custom-datepicker"
          />
        </div>
        <div
          style={{
            margin: "33px 0px 20px 0px",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button className="submit-date" onClick={handleSubmit} type="button">
            Submit
          </button>
          <button className="cancel-model" onClick={onClose} type="button">
            Close
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default function Navbar() {
  const [showBookingSlot, setShowBookingSlot] = useState(false);

  const toggleBookingSlot = () => {
    setShowBookingSlot(!showBookingSlot);
  };

  const handleCloseModal = () => {
    setShowBookingSlot(false);
  };
  return (
    <>
      <header className="header">
        <div className="logo">
          <Link to="/">
            <img
              className="logo-image"
              src="./Assets/logo-image.png"
              alt="logo"
            />
          </Link>

          <img
            className="menu-icon"
            onClick={toggleMenu}
            src="./Assets/menu-bar.png"
            style={{ width: "40px", height: "40px" }}
            alt="menu icon"
          />
        </div>

        <ul className="nav-menu">
          <li>
            <NavLink to="/" activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/realestate" activeClassName="active">
              Real Estate
            </NavLink>
          </li>
          <li>
            <NavLink to="/corporate events">Corporate Events</NavLink>
          </li>
          <li>
            <NavLink to="/spell" activeClassName="active">
              Spell Bee
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" activeClassName="active">
              Contact Us
            </NavLink>
          </li>
          <li>
            <a href="#">Login</a>
          </li>
        </ul>
        <BookingSlot show={showBookingSlot} onClose={handleCloseModal} />
      </header>
    </>
  );
}
const App = () => {
  return (
    <div>
      <Navbar />
    </div>
  );
};
