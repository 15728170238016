import React, { useState, useEffect } from "react";

export default function About() {
  const images = [
    "/Assets/slider-image.png",
    "/Assets/slider-image1.png",
    "/Assets/slider-image.png",
  ];

  const texts = [
    {
      heading: "Corporate Event Services Bringing Your Vision to Life.",
      paragraph:
        "As leaders in the industry, we combine passion, precision, and creativity to design and execute exceptional events that exceed expectations.",
      button: "Book Event",
    },
    {
      heading: "Spell Bee Services Igniting Young Minds",
      paragraph:
        "Ignite young minds with our Spell Bee Services, fostering talent and creating opportunities for growth. Experience the excitement as we bring your schools vision to life",
      button: "Book Event",
    },
    {
      heading: "The #1 site Real Estate professionals trust*",
      paragraph:
        "Top real estate professionals trust us for our expertise, extensive property portfolio, and exceptional service. Discover the support you need to succeed.",
      button: "Book Property",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <div className="slider-section-images">
        <div className="image-overlay"></div>
        <img
          src={images[currentIndex]}
          style={{ width: "100%" }}
          alt="Slider"
        />
        <div className="slider-section-text">
          <h1 className="slider-h1-text">{texts[currentIndex].heading}</h1>
          <p className="slider-h1-text1">{texts[currentIndex].paragraph}</p>
          <div className="corporate-section">
            <button className="corporate-btn">
              {texts[currentIndex].button}
              <img
                style={{ paddingLeft: "10px" }}
                src="/Assets/arrow1.png"
                alt="Arrow"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="main-section" style={{ backgroundColor: "#edfaf9" }}>
        <div className="about-section">
          <div className="" style={{ marginLeft: "2rem" }}>
            <p class="about">About Us</p>
            <p className="about-text">
              At V2 Royals, our passion lies in crafting unforgettable
              experiences in three core areas: real estate properties, corporate
              event management, and school competition events for children. With
              a commitment to excellence, we blend creativity and precision to
              ensure every project surpasses expectations. From helping clients
              find their dream properties to orchestrating seamless corporate
              events and fostering educational competitions for children, our
              team is dedicated to delivering unparalleled service. We pride
              ourselves on our ability to innovate and tailor each experience to
              our clients' unique needs, ensuring lasting memories and
              successful outcomes. Join us as we redefine excellence across
              these dynamic sectors, one exceptional experience at a time.
            </p>
          </div>
          <img className="about-image" src="./Assets/about-image.png" />
          <img
            className="logo-bacground"
            src="/Assets/logo-bacground.png"
          ></img>
        </div>
        <div className="vision-section">
          <p className="our-vision-head">Our Vission Mission Goal</p>
          <div className="vision-section-main">
            <div>
              <img
                className="vision-image"
                src="/Assets/vision-image.png"
              ></img>
            </div>
            <div className="vision-section-main-right">
              <div className="vision-section-main-right-sub">
                <div className="circle">
                  <img
                    className="vission-icon"
                    src="/assets/vission-icon.png"
                  ></img>
                </div>
                <div className="vision-section-main-right-text">
                  <p className="vision-sub-text">Vission</p>
                  <p className="vision-sub-text1">
                    To be the leading provider of innovative and exceptional
                    services in real estate, corporate event management, and
                    school competition events, transforming each client's vision
                    into reality.
                  </p>
                </div>
              </div>
              <div className="vision-section-main-right-sub">
                <div className="circle">
                  <img
                    className="vission-icon"
                    src="/assets/mission-icon.png"
                  ></img>
                </div>
                <div className="vision-section-main-right-text">
                  <p className="vision-sub-text">Mission</p>
                  <p className="vision-sub-text1">
                    {" "}
                    Our mission is to deliver unparalleled service and
                    excellence across all our domains. We strive to exceed
                    expectations by blending creativity, precision, and
                    dedication, ensuring successful outcomes and lasting
                    impressions for our clients.
                  </p>
                </div>
              </div>
              <div className="vision-section-main-right-sub">
                <div className="circle">
                  <img
                    className="vission-icon"
                    src="/assets/values-icon.png"
                  ></img>
                </div>
                <div className="vision-section-main-right-text">
                  <p className="vision-sub-text">Goal</p>
                  <p className="vision-sub-text1">
                    We aim to continuously innovate and provide top-quality
                    services in real estate, event management, and educational
                    competitions, build lasting client relationships, foster
                    growth and creativity, and achieve sustainable growth with a
                    commitment to quality and integrity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
