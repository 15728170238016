import React, { useState, useEffect } from "react";
import About from "../About/About";
export default function Main() {
  const services = [
    {
      src: "/Assets/image-gird3.png",
      text: "Real-estate Properties",
      btn: "Know More",
      src1: "/Assets/arrow.png",
    },
    {
      src: "/Assets/image-gird2.png",
      text: "Corporate Events",
      btn: "Know More",
      src1: "/Assets/arrow.png",
    },
    {
      src: "/Assets/image-gird1.png",
      text: "Spell Bee",
      btn: "Know More",
      src1: "/Assets/arrow.png",
    },
  ];
  const stories = [
    {
      src: "/Assets/story1.png",
      text: "Artist Management",
      text1:
        "We handle artist bookings and arrangements with precision, ensuring exceptional performances at every event.",
    },
    {
      src: "/Assets/story2.png",
      text: "Event production",
      text1:
        "We efficiently oversee and coordinate every detail of event execution, ensuring seamless and successful outcomes.",
    },
    {
      src: "/Assets/story3.png",
      text: "Venue Sourcing",
      text1:
        "We excel in locating the perfect venue to ensure the success of your event, tailored to your specific needs and preferences.",
    },
  ];

  return (
    <>
      <About />
      <div className="service-section">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "36px 0px 0px 0px",
          }}
        >
          <p className="service-btn">Our Services</p>
        </div>
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <p className="service-texts">
            We craft memorable corporate events and inspire excellence through
            dynamic student competition programs, while offering comprehensive
            real estate solutions to meet all your property needs with expertise
            and dedication.
          </p>
        </div>
        <div className="service-container">
          <div className="service-images">
            {services.map((item) => (
              <div className="service-content">
                <img className="serivice-image" src={item.src}></img>
                <p className="service-text">{item.text}</p>
                <div className="service-btn-section">
                  <div className="service-know-section">
                    <p className="service-text1">{item.btn}</p>
                    <img className="serivice-image" src={item.src1}></img>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ backgroundColor: "#edfaf9" }}>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "36px 0px 0px 0px",
            }}
          >
            <p className="service-btn">
              Success Stories: Recently Completed Event Management Services
            </p>
          </div>
          <div
            className=""
            style={{ display: "flex", justifyContent: "center" }}
          >
            <p className="service-texts">
              Explore our latest successes in event management, where we've
              transformed visions into reality. From intimate gatherings to
              grand celebrations, our team has meticulously orchestrated every
              detail to create unforgettable experiences. Discover how we've
              brought dreams to life and exceeded expectations in our recent
              projects.
            </p>
          </div>
          <div className="story-section">
            {stories.map((item) => (
              <div className="story-container">
                <img className="story-image" src={item.src}></img>
                <p className="story-text">{item.text}</p>
                <p className="story-text1">{item.text1}</p>
              </div>
            ))}
          </div>
          <div className="corporate-section">
            <button className="corporate-btn">
              View All Corporate Events{" "}
              <img
                style={{ paddingLeft: "10px" }}
                src="/Assets/arrow1.png"
              ></img>
            </button>
          </div>
        </div>
        <div
          className="directors-section"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "36px 0px 0px 0px",
            }}
          >
            <p className="service-btn">Meet Our Directors</p>
          </div>
          <div className="director-containers">
            <div className="director-container1">
              <img className="director-image" src="/Assets/director.png"></img>
              <p
                style={{
                  fontWeight: "600",
                  color: "#2B2B2B",
                  fontSize: "2opx",
                  textAlign: "center",
                }}
              >
                Vijay Kumar S
              </p>
              <p
                style={{
                  fontWeight: "600",
                  color: "#777777",
                  fontSize: "2opx",
                  textAlign: "center",
                }}
              >
                Chairman
              </p>
            </div>
            <div className="director-container1">
              <img className="director-image" src="/Assets/director.png"></img>
              <p
                style={{
                  fontWeight: "600",
                  color: "#2B2B2B",
                  fontSize: "2opx",
                  textAlign: "center",
                }}
              >
                Vijetha ML
              </p>
              <p
                style={{
                  fontWeight: "600",
                  color: "#777777",
                  fontSize: "2opx",
                  textAlign: "center",
                }}
              >
                MD & CEO
              </p>
            </div>
          </div>
        </div>
        <div className="v2-royals" style={{ backgroundColor: "#edfaf9" }}>
          <div className="v2-royals-left">
            <p className="v2-royals-text">Let’s simply begin with V2 Royals.</p>
          </div>
          <div className="v2-royals-right">
            <p className="v2-royals-text1">
              "Get Started" to explore how we can exceed your expectations in
              real estate, event management, and educational competitions.
              Experience excellence with us today.
            </p>
            <div className="corporate-section1">
              <button className="corporate-btn">
                Get Started{" "}
                <img
                  style={{ paddingLeft: "10px" }}
                  src="/Assets/arrow1.png"
                ></img>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
