import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About";
import Main from "./components/Main/Main";
import Footer from "./components/Footer/Footer";
import RealEstate from "./components/RealEstate/RealEstate";
import Corporate from "./components/Corporate/Corporate";
import Spell from "./components/Spell/Spell";
import Contact from "./components/Contact/Contact";
const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/realestate" element={<RealEstate />}></Route>
          <Route path="/corporate events" element={<Corporate />}></Route>
          <Route path="/spell" element={<Spell />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
