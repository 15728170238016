import React from "react";

export default function Spell() {
  const events = [
    {
      src: "/Assets/spell.png",
      text: "Creative Exploration",
      src1: "/Assets/call.png",
      text1: "View Event Details",
      text3:
        "Unleash the potential of young minds with our Creative Exploration events for students, designed to inspire and innovate..... Read More",
      text2: "Call",
    },
    {
      src: "/Assets/spell1.png",
      text: "Pop Culture Art",
      src1: "/Assets/call.png",
      text1: "View Event Details",
      text3:
        "Immerse your students in the vibrant world of Pop Culture Art, where creativity meets contemporary trends...... Read More",
      text2: "Call",
    },
    {
      src: "/Assets/spell2.png",
      text: "Drawing",
      src1: "/Assets/call.png",
      text1: "View Event Details",
      text3:
        "Unleash your creativity at our Drawing for Students events, where young artists can explore their talents and.... Read More",
      text2: "Call",
    },
  ];
  return (
    <>
      <div className="event-section">
        <p className="service-btn">Spell Bee</p>
        <div className="real-estate-container">
          <p className="real-estate-text">
          Discover our handpicked selection of children's creative Spell Bee events. Each listing offers a glimpse into engaging and inspiring activities available through V2 Royals. Click "View Details" for more information.
          </p>
          <div className="corporate-section">
            <p className="real-btn">
              View All Events{" "}
              <img
                style={{ paddingLeft: "10px" }}
                src="/Assets/arrow.png"
              ></img>
            </p>
          </div>
        </div>
        <div className="joint-venture">
          {events.map((item) => (
            <div className="joint-venture-section">
              <img src={item.src}></img>
              <p className="story-text">{item.text}</p>
              <p className="story-text1">{item.text3}</p>
              <div className="section-btn">
                <div className="corporate-section">
                  <button className="real-btns">
                    {item.text2}{" "}
                    <img
                      style={{ paddingLeft: "10px" }}
                      src="/Assets/call.png"
                    ></img>
                  </button>
                </div>
                <div className="corporate-section">
                  <p className="corporate-btns">
                    {item.text1}{" "}
                    <img style={{ paddingLeft: "10px" }} src={item.scr1}></img>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="service-btn">
        Welcome to V2 Royals Spell Bee Organizer
        </p>
        <p className="real-estate-text">
        V2 Royals is dedicated to fostering creativity, innovation, and teamwork among students through exciting and enriching competitions. With years of experience in organizing successful events, we are passionate about providing opportunities for students to showcase their talents and abilities in a supportive and inclusive environment.
        </p>
        <div className="comprehensive-image-section">
          <img
            className="comprehensive-image"
            src="/Assets/spell-image.png"
          ></img>

          <div className="comprehensive-text">
            <p className="comprehensive-text1">All Spell Bee Events</p>
            <hr></hr>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>Creative Exploration</option>
                </select>
              </div>
            </div>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>Pop Culture Art</option>
                </select>
              </div>
            </div>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>Drawing</option>
                </select>
              </div>
            </div>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>Out-door Events</option>
                </select>
              </div>
            </div>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>In-door Events</option>
                </select>
              </div>
            </div>
            <div className="corporate-section3">
              <button className="corporate-btn">Contact Us </button>
            </div>
          </div>
          <div className="contact-section">
            <p className="contact-text">Connect With Us</p>
            <p className="contact-text1">
              Let’s Plan Your Perfect Event Together.
            </p>
            <div className="form-section">
              <div className="form-container">
                <label className="label">First Name *</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Name"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Phone Number *</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Number"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Email Address *</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Email"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Company</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="How Can We Help You ?"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Country</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Your Country"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">State</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Your State"
                ></input>
              </div>

              <div className="form-container2">
                <label className="label">Message</label>
                <br></br>
                <div style={{ display: "flex" }}>
                  <input
                    className="contact-form-text2"
                    type="text"
                    placeholder="Message"
                  ></input>
                </div>
                <div className="corporate-section3">
                  <button className="corporate-btn">Send Message </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
