import React from "react";

export default function Corporate() {
  const events = [
    {
      src: "/Assets/event2.png",
      text: "New Year Event",
      src1: "/Assets/call.png",
      text1: "View Event Details",
      text3:
        "Join us at our expertly organized New Year Event, where celebrants gather to ring in the year with festivities, joy, and memorable ..... Read More",
      text2: "Call",
    },
    {
      src: "/Assets/event.png",
      text: "Team-Building Events",
      src1: "/Assets/call.png",
      text1: "View Event Details",
      text3:
        "Enhance teamwork and collaboration with our dynamic Team-Building Events, designed to strengthen bonds and boost morale..... Read More",
      text2: "Call",
    },
    {
      src: "/Assets/event1.png",
      text: "Board Meetings",
      src1: "/Assets/call.png",
      text1: "View Event Details",
      text3:
        "Ensure your Board Meetings run seamlessly with our professional event planning services, tailored for executive efficiency..... Read More",
      text2: "Call",
    },
  ];
  return (
    <>
      <div className="event-section">
        <p className="service-btn">Trending Corporate Events</p>
        <div className="real-estate-container">
          <p className="real-estate-text">
            Explore our curated collection of trending events. Each listing
            offers a sneak peek into exciting experiences and must-attend
            gatherings available through V2 Royals. Click "View Details" for
            more information
          </p>
          <div className="corporate-section">
            <p className="real-btn">
              View All Events{" "}
              <img
                style={{ paddingLeft: "10px" }}
                src="/Assets/arrow.png"
              ></img>
            </p>
          </div>
        </div>
        <div className="joint-venture">
          {events.map((item) => (
            <div className="joint-venture-section">
              <img src={item.src}></img>
              <p className="story-text">{item.text}</p>
              <p className="story-text1">{item.text3}</p>
              <div className="section-btn">
                <div className="corporate-section">
                  <button className="real-btns">
                    {item.text2}{" "}
                    <img
                      style={{ paddingLeft: "10px" }}
                      src="/Assets/call.png"
                    ></img>
                  </button>
                </div>
                <div className="corporate-section">
                  <p className="corporate-btns">
                    {item.text1}{" "}
                    <img style={{ paddingLeft: "10px" }} src={item.scr1}></img>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="service-btn">
          Our Comprehensive Corporate Event Services
        </p>
        <p className="real-estate-text">
          Creating memorable moments for our clients is at the heart of what we
          do. With a strong network of artists and experts across various fields
          such as art, music, culture, sports, and business management, we are
          one of the most promising event management companies in Bengaluru
        </p>
        <div className="comprehensive-image-section">
          <img
            className="comprehensive-image"
            src="/Assets/comprehensive.png"
          ></img>

          <div className="comprehensive-text">
            <p className="comprehensive-text1">All Corporate Events</p>
            <hr></hr>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>Charity Events and Fundraisers</option>
                </select>
              </div>
            </div>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>Conferences and Seminars</option>
                </select>
              </div>
            </div>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>Awards and Recognition Ceremonies</option>
                </select>
              </div>
            </div>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>Product Launches</option>
                </select>
              </div>
            </div>
            <div className="comprehensive-form-section">
              <div className="comprehensive-form-container">
                <select
                  className="comprehensive-input-form-text"
                  placeholder="Select Location"
                >
                  Select Location
                  <option>In-door Events</option>
                </select>
              </div>
            </div>
            <div className="corporate-section3">
              <button className="corporate-btn">Contact Us </button>
            </div>
          </div>
          <div className="contact-section">
            <p className="contact-text">Connect With Us</p>
            <p className="contact-text1">
              Let’s Plan Your Perfect Event Together.
            </p>
            <div className="form-section">
              <div className="form-container">
                <label className="label">First Name *</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Name"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Phone Number *</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Number"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Email Address *</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Email"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Company</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="How Can We Help You ?"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Country</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Your Country"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">State</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Your State"
                ></input>
              </div>

              <div className="form-container2">
                <label className="label">Message</label>
                <br></br>
                <div style={{ display: "flex" }}>
                  <input
                    className="contact-form-text2"
                    type="text"
                    placeholder="Message"
                  ></input>
                </div>
                <div className="corporate-section3">
                  <button className="corporate-btn">Send Message </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
