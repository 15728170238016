import React from "react";

export default function RealEstate() {
  const properies = [
    {
      src: "/Assets/property.png",
      text: "Residential Plot / Land",
      src1: "/Assets/call.png",
      text1: "View Property Details",
      text3:
        "1320 Square feet Plot for sale in Bileshivale, Bengaluru. This land has a dimension of 44.0 mt length 30.0 mt width.... Read More",
      text2: "Call",
    },
    {
      src: "/Assets/property1.png",
      text: "Residential Plot / Land",
      src1: "/Assets/call.png",
      text1: "View Property Details",
      text3:
        "1320 Square feet Plot for sale in Bileshivale, Bengaluru. This land has a dimension of 44.0 mt length 30.0 mt width.... Read More",
      text2: "Call",
    },
    {
      src: "/Assets/property2.png",
      text: "Residential Plot / Land",
      src1: "/Assets/call.png",
      text1: "View Property Details",
      text3:
        "1320 Square feet Plot for sale in Bileshivale, Bengaluru. This land has a dimension of 44.0 mt length 30.0 mt width.... Read More",
      text2: "Call",
    },
  ];
  const rated = [
    {
      src: "/Assets/rated1.png",
      text: "Seaside Serenity Villa",
      src1: "/Assets/call.png",
      text1: "View Property Details",
      text3:
        "A stunning 4-bedroom, 3-bathroom villa in a peaceful suburban neighborhood... Read More",
      text2: "Call",
    },
    {
      src: "/Assets/rated2.png",
      text: "Metropolitan Haven",
      src1: "/Assets/call.png",
      text1: "View Property Details",
      text3:
        "A chic and fully-furnished 2-bedroom apartment with panoramic city views... Read More",
      text2: "Call",
    },
    {
      src: "/Assets/rated3.png",
      text: "Rustic Retreat Cottage",
      src1: "/Assets/call.png",
      text1: "View Property Details",
      text3:
        "An elegant 3-bedroom, 2.5-bathroom townhouse in a gated community... Read More",
      text2: "Call",
    },
  ];
  return (
    <>
      <div className="real-estate-section">
        <p className="service-btn">Joint Venture Properties</p>
        <div className="real-estate-container">
          <p className="real-estate-text">
            Explore our handpicked selection of featured properties. Each
            listing offers a glimpse into exceptional homes and investments
            available through V2 Royals. Click "View Details" for more
            information.
          </p>
          <div className="corporate-section">
            <p className="real-btn">
              View All Properties{" "}
              <img
                style={{ paddingLeft: "10px" }}
                src="/Assets/arrow.png"
              ></img>
            </p>
          </div>
        </div>
        <div className="joint-venture">
          {properies.map((item) => (
            <div className="joint-venture-section">
              <img src={item.src}></img>
              <p className="story-text">{item.text}</p>
              <p className="story-text1">{item.text3}</p>
              <div className="section-btn">
                <div className="corporate-section">
                  <button className="real-btns">
                    {item.text2}{" "}
                    <img
                      style={{ paddingLeft: "10px" }}
                      src="/Assets/call.png"
                    ></img>
                  </button>
                </div>
                <div className="corporate-section">
                  <p className="corporate-btns">
                    {item.text1}{" "}
                    <img style={{ paddingLeft: "10px" }} src={item.scr1}></img>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="service-btn">Out Rated Properties</p>
        <div className="real-estate-container">
          <p className="real-estate-text">
            Explore our handpicked selection of Out Rated properties. Each
            listing offers a glimpse into exceptional homes and investments
            available through V2 Royals. Click "View Details" for more
            information.
          </p>
          <div className="corporate-section">
            <p className="real-btn">
              View All Properties{" "}
              <img
                style={{ paddingLeft: "10px" }}
                src="/Assets/arrow.png"
              ></img>
            </p>
          </div>
        </div>
        <div className="joint-venture">
          {rated.map((item) => (
            <div className="joint-venture-section">
              <img src={item.src}></img>
              <p className="story-text">{item.text}</p>
              <p className="story-text1">{item.text3}</p>
              <div className="section-btn">
                <div className="corporate-section">
                  <button className="real-btns">
                    {item.text2}{" "}
                    <img
                      style={{ paddingLeft: "10px" }}
                      src="/Assets/call.png"
                    ></img>
                  </button>
                </div>
                <div className="corporate-section">
                  <p className="corporate-btns">
                    {item.text1}{" "}
                    <img style={{ paddingLeft: "10px" }} src={item.scr1}></img>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="service-btn">Let's Bring It to Life</p>
        <div className="real-estate-container">
          <p className="real-estate-text">
            Ready to find your dream property? Fill out the form below, and our
            real estate experts will find the perfect match for you. Let's
            embark on this exciting journey together!
          </p>
        </div>
        <div className="form-section">
          <div className="form-container">
            <label className="label">First Name</label>
            <br></br>
            <input
              className="input-form-text"
              type="text"
              placeholder="Enter First Name"
            ></input>
          </div>
          <div className="form-container">
            <label className="label">Last Name</label>
            <br></br>
            <input
              className="input-form-text"
              type="text"
              placeholder="Enter Last Name"
            ></input>
          </div>
          <div className="form-container">
            <label className="label">Email</label>
            <br></br>
            <input
              className="input-form-text"
              type="text"
              placeholder="Enter your Email"
            ></input>
          </div>
          <div className="form-container">
            <label className="label">Phone</label>
            <br></br>
            <input
              className="input-form-text"
              type="text"
              placeholder="Enter Phone Number"
            ></input>
          </div>
          <div className="form-container">
            <label className="label">Preferred Location</label>
            <br></br>
            <select className="input-form-text" placeholder="Select Location">
              Select Location
              <option>Select Location</option>
              <option>Bangalore</option>
              <option>Chennai</option>
            </select>
          </div>
          <div className="form-container">
            <label className="label">Property Type</label>
            <br></br>
            <select className="input-form-text">
              <option>Select Property Type</option>
            </select>
          </div>
          <div className="form-container">
            <label className="label">No. of Bathrooms</label>
            <br></br>
            <select className="input-form-text">
              <option>SSelect no. of Bedrooms</option>
            </select>
          </div>
          <div className="form-container">
            <label className="label">No. of Bedrooms</label>
            <br></br>
            <select className="input-form-text">
              <option>Select no. of Bedrooms</option>
            </select>
          </div>
          <div className="form-container">
            <label className="label">Budget</label>
            <br></br>
            <select className="input-form-text">
              <option>Select Budget</option>
            </select>
          </div>
          <div className="form-container1">
            <label className="label">Preferred Contact Method</label>
            <br></br>
            <div style={{ display: "flex" }}>
              <input
                className="input-form-text1"
                type="text"
                placeholder="Enter Your Number"
              ></input>
              <input
                className="input-form-text1"
                type="text"
                placeholder="Enter Your Email"
              ></input>
            </div>
          </div>
          <div className="form-container2">
            <label className="label">Message</label>
            <br></br>
            <div style={{ display: "flex" }}>
              <input
                className="input-form-text2"
                type="text"
                placeholder="Enter your Message here.."
              ></input>
            </div>
            <div className="corporate-section2">
              <button className="corporate-btn">Send Your Message </button>
            </div>
          </div>
        </div>
        {/* <div
          className=""
          style={{
            display: "flex",
            padding: "36px 0px 0px 0px",
          }}
        >
          <p className="service-btn">Start Your Real Estate Journey Today</p>
        </div>
        <p className="real-estate-text">
          Your dream property is just a click away. Whether you're looking for a
          new home, a strategic investment, or expert real estate advice,
          Estatein is here to assist you every step of the way. Take the first
          step towards your real estate goals and explore our available
          properties or get in touch with our team for personalized assistance.
        </p>
        <div className="corporate-section1">
          <p className="real-btn">
            Explore Properties{" "}
            <img style={{ paddingLeft: "10px" }} src="/Assets/arrow.png"></img>
          </p>
        </div> */}
      </div>
    </>
  );
}
