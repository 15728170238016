import React from "react";

export default function Contct() {
  return (
    <>
      <div className="spell-section">
        <img className="contact-image" src="/Assets/contact-image.png"></img>
        <div className="comprehensive-image-section">
          <div className="contact-section-contact">
            <p className="contact-text">Connect With Us</p>
            <p className="contact-text1">
              Let’s Plan Your Perfect Event Together.
            </p>
            <div className="form-section">
              <div className="form-container">
                <label className="label">First Name *</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Name"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Phone Number *</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Number"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Email Address *</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Email"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Company</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="How Can We Help You ?"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">Country</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Your Country"
                ></input>
              </div>
              <div className="form-container">
                <label className="label">State</label>
                <br></br>
                <input
                  className="contact-form-text"
                  type="text"
                  placeholder="Your state"
                ></input>
              </div>

              <div className="form-container2">
                <label className="label">Message</label>
                <br></br>
                <div style={{ display: "flex" }}>
                  <input
                    className="contact-form-text2"
                    type="text"
                    placeholder="Message"
                  ></input>
                </div>
                <div className="corporate-section3">
                  <button className="corporate-btn">Send Message </button>
                </div>
              </div>
            </div>
          </div>
          <div className="address-section">
            <div className="address-container">
              <p className="address-text">Office Address</p>
              <div className="border-line"></div>
            </div>
            <div className="addres-text-details-section">
              <div className="addres-text-details">
                <p className="addres-text">V2 Royals</p>
                <p className="addres-text1">
                  121, 1st floor, 3rd cross, 4th main
                </p>
                <p className="addres-text1">srinivasanagar, Bangalore-560050</p>
              </div>
              <div className="addres-text-details">
                <p className="addres-text">Phone number</p>
                <p className="addres-text1">+91 81971 89899</p>
                <p className="addres-text1">+91 91132 53966</p>
              </div>
              <div className="addres-text-details">
                <p className="addres-text">Email Address</p>
                <p className="addres-text1">vijay@v2royals.com</p>
                <p className="addres-text1">vijetha@v2royals.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
